import React, { useState } from "react";
import { bool, func, shape } from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import FormControl from "components/forms/shared/Control";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/shared/FormGroup";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import {
  useCreateContentUploadMutation,
  useGetCategoryListQuery,
  useGetUploadScheduleDatesQuery,
  useUpdateContentUploadMutation,
} from "services/projectCity";
import {
  generateFormData,
  handleResponseError,
  isOverThreshold,
} from "utils/forms";
import { formatTimeStamp } from "utils/datetime";
import { contentUploadStatus, contentUploadType } from "features/sprint/enums";
import FileUploadFormGroup from "components/forms/fields/FileUploadFormGroup";
import { addContentUpload } from "features/buckets/slice";
import { ContentUpload } from "features/sprint/types";
import { updateContentUpload as updateBucketContentUpload } from "features/buckets/slice";
import ToggleBox from "components/controls/ToggleBox";
import Select from "components/controls/Select";
import { bucketType } from "utils/enums";
import { convertEnumToArray } from "utils/general";
import { canUploadPremiumContent } from "utils/permissions";
import UploadedVideoLabel from "components/text/UploadedVideoLabel";

function VideoContentForm({
  setMultipartFile,
  setContentUpload,
  closeModal,
  contentUpload,
  chooseType,
  chooseInSprint,
  chooseBucket,
  promptChooseCategory,
  followupAction,
  ...props
}) {
  /** Form for creating or updating a content upload video. */
  const [formError, setFormError] = useState();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: contentUpload || {
      type: contentUploadType.animatic,
    },
  });

  const currentBucketId = useSelector((state) => state.buckets.current.id);
  const dispatch = useDispatch();

  const { data: categories } = useGetCategoryListQuery();
  const { user } = useSelector((state) => state.account);
  const {
    data: scheduleData,
    isLoading: scheduleDataIsLoading,
  } = useGetUploadScheduleDatesQuery({
    is_public: true,
  });

  const [createContentUpload] = useCreateContentUploadMutation();
  const [updateContentUpload] = useUpdateContentUploadMutation();

  const isUpdating = contentUpload?.id;
  const isLesson = contentUpload?.type === contentUploadType.lesson;
  const canUploadLesson = canUploadPremiumContent(user);

  function handlePostSave(contentUpload) {
    // After the instance is saved, then we can do some post processing.
    if (!isUpdating && contentUpload.bucket) {
      // Add the created instance into the bucket redux state
      dispatch(addContentUpload({ bucketId: currentBucketId, contentUpload }));
    }
  }

  async function onSubmit(data) {
    /** When we create a project, there are some default values that need to be set. */

    let videoFile = null;
    const overThreshold = isOverThreshold(data.file?.size);

    if (overThreshold) {
      videoFile = data.file;
      delete data.file;
    } else {
      data.status = contentUploadStatus.uploaded;
    }

    if (!data.type)
      data.type = contentUpload.type || contentUploadType.animatic;

    // Clean the on/off switch data...
    data.inSprint = data.inSprint === "on" || data.inSprint === true;

    // Clean the bucket data receiving an object
    if (data.bucket && typeof data.bucket === "object")
      data.bucket = data.bucket.value;

    // For some reason camel case is not working with parser.
    if (data.type === contentUploadType.animatic) {
      data.isVisible = true;
    }
    data.is_visible = data.isVisible;
    data.in_sprint = data.inSprint;

    // Set the bucket data manually for now.
    if (contentUpload.bucket && data.bucket === null)
      data.bucket = contentUpload.bucket;

    // Some more cleanup of undefined and file data.
    if (
      data.thumbnail === null ||
      !data.thumbnail ||
      data.thumbnail?.size === undefined
    )
      delete data.thumbnail;
    if (data.file === null || !data.file || data.file?.size === undefined)
      delete data.file;

    if (data.url === undefined) data.url = "";
    if (data.categories === undefined) delete data.categories;
    if (data.bucket === undefined) delete data.bucket;
    if (data.bucket === null) data.bucket = "";
    // if (data.schedule === undefined) data.schedule = "";
    if (data.schedule === undefined || data.schedule === null)
      data.schedule = "";

    // Finalize the data and send request.
    const formData = generateFormData(data);
    const action = isUpdating ? updateContentUpload : createContentUpload;
    let payload = {};

    if (isUpdating) {
      payload = {
        contentUploadId: contentUpload.id,
        formData,
      };
    } else payload = formData;

    const response = await action(payload);

    if (response.error) {
      handleResponseError(response, setFormError, setError);
    } else {
      if (!overThreshold) {
        // If it's a lesson without a category selected, we should prompt to choose a category.
        if (
          response.data.type === contentUploadType.lesson &&
          response.data.categories.length === 0 &&
          !isUpdating
        ) {
          promptChooseCategory(response.data);
        }

        dispatch(
          updateBucketContentUpload({
            bucketId: currentBucketId,
            contentUpload: response.data,
          })
        );

        closeModal();
      } else {
        setMultipartFile(videoFile);
        if (!isUpdating) setContentUpload(response.data);
      }

      followupAction();
    }

    handlePostSave(response.data);
  }

  function getProjectTitle(bucket) {
    return `${bucket.title} (${bucket.project})`;
  }

  function getValidBuckets() {
    if (!chooseBucket) return [];

    // Return a filtered set of content project buckets that should be valid for the content upload.
    const all = user.adminContentUploadBuckets.map((bucket) => ({
      value: bucket.id,
      label: getProjectTitle(bucket),
      kind: bucket.kind,
    }));

    if (contentUpload?.type === contentUploadType.animatic) {
      return all.filter((bucket) => bucket.kind === bucketType.animatics);
    } else if (contentUpload?.type === contentUploadType.lesson) {
      return all.filter((bucket) => bucket.kind === bucketType.lessons);
    } else return all;
  }

  function getDefaultBucketValue() {
    if (contentUpload.bucket) {
      const bucket = user.adminContentUploadBuckets.find(
        (bucket) => bucket.id === contentUpload.bucket
      );
      if (bucket) {
        return {
          value: bucket.id,
          label: getProjectTitle(bucket),
        };
      }
    }
    return null;
  }

  function getScheduleLabel(scheduleDate) {
    /** Get the description label that should be shown for a schedule date. */
    return `${scheduleDate.description} by ${
      scheduleDate.user.username
    } on ${formatTimeStamp(scheduleDate.datetime)}`;
  }

  function getDefaultScheduleValue() {
    if (scheduleDataIsLoading) return null;

    if (contentUpload.schedule) {
      const schedule = scheduleData.find(
        (schedule) => schedule.id === contentUpload.schedule
      );
      if (schedule) {
        const selectValue = {
          value: schedule.id,
          label: getScheduleLabel(schedule),
        };
        return selectValue;
      }
    }

    return null;
  }

  function renderDefaultCategories() {
    return contentUpload?.categories
      ? contentUpload.categories.map((contentCategory) => {
          const obj = categories.find(
            (category) => category.id === contentCategory
          );
          return { value: obj.id, label: obj.title };
        })
      : [];
  }

  function getDefaultTypeChoice() {
    return convertEnumToArray(contentUploadType).filter(
      (choice) => choice.value === contentUpload?.type
    );
  }

  function getScheduleItems() {
    return scheduleDataIsLoading
      ? []
      : scheduleData.map((schedule) => ({
          value: schedule.id,
          label: getScheduleLabel(schedule),
        }));
  }

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup label="Title" errors={errors.title}>
        <Controller
          as={FormControl}
          name="title"
          rules={{ required: true }}
          control={control}
          isInvalid={errors.title !== undefined}
        />
      </FormGroup>
      {canUploadLesson && chooseType && (
        <FormGroup label="Type" errors={errors.title}>
          <Controller
            render={(props) => (
              <Select
                options={convertEnumToArray(contentUploadType)}
                defaultValue={getDefaultTypeChoice()}
                onChange={(choice) => props.onChange(choice.value)}
                placeholder="Video type"
              />
            )}
            control={control}
            name="type"
          />
        </FormGroup>
      )}
      <FileUploadFormGroup
        label="Thumbnail"
        field="thumbnail"
        errors={errors}
        control={control}
        tooltipText="Thumbnail images cropped to video aspect ratio (1280x720)"
        current={contentUpload?.thumbnail?.name}
      />
      {isLesson && (
        <FormGroup
          label="Pre-release url"
          errors={errors.title}
          tooltipText="Redirect users to a url before posting a video."
        >
          <Controller
            as={FormControl}
            name="url"
            control={control}
            isInvalid={errors.url !== undefined}
            placeholder="Must include protocol (http://, https://, etc)"
          />
        </FormGroup>
      )}
      {isLesson && (
        <FormGroup
          label="Schedule"
          errors={errors.schedule}
          tooltipText="Link to a schedule item to get date/time for sending notifications."
        >
          <Controller
            render={({ onChange }) => (
              <Select
                options={getScheduleItems()}
                isClearable
                placeholder="Schedule (optional)"
                defaultValue={getDefaultScheduleValue()}
                onChange={(option) => onChange(option?.value)}
              />
            )}
            control={control}
            name="schedule"
          />
        </FormGroup>
      )}
      <FormGroup label="Video File" errors={errors.file}>
        <Controller
          render={({ onChange }) => (
            <>
              <FormControl
                type="file"
                error={errors.text}
                onChange={(e) => onChange(e.target.files[0])}
              />
              {contentUpload.file && (
                <UploadedVideoLabel>
                  Current - {contentUpload.file.name}
                </UploadedVideoLabel>
              )}
            </>
          )}
          rules={{ required: !isLesson && !isUpdating }}
          defaultValue=""
          name="file"
          accept="video/mp4"
          control={control}
          isInvalid={errors.file !== undefined}
        />
      </FormGroup>
      <FormGroup label="Categories" errors={errors.categories}>
        <Controller
          render={({ onChange, ref }) => (
            <Select
              options={categories.map((c) => ({ label: c.title, value: c.id }))}
              isMulti
              ref={ref}
              closeMenuOnSelect={false}
              onChange={(objects) => onChange(objects.map((obj) => obj.value))}
              defaultValue={renderDefaultCategories()}
              placeholder="Content categories"
            />
          )}
          control={control}
          name="categories"
        />
      </FormGroup>
      <FormGroup
        label="Page"
        errors={errors.bucket}
        className={!chooseBucket && "d-none"}
        tooltipText="Optionally add content upload to a project page."
      >
        <Controller
          render={({ onChange }) => (
            <Select
              options={getValidBuckets()}
              isClearable
              placeholder="Choose page (optional)"
              defaultValue={getDefaultBucketValue()}
              onChange={(option) => onChange(option)}
            />
          )}
          control={control}
          name="bucket"
        />
      </FormGroup>
      {isLesson && (
        <ToggleBox
          label="Is Visible?"
          controllerProps={{ name: "isVisible", control, defaultValue: true }}
          tooltipText="Either allow subscribers to view, or keep it to yourself for now."
        />
      )}
      <ToggleBox
        label="Show in sprint?"
        onLabel="Yes"
        offLabel="No"
        className={!chooseInSprint && "d-none"}
        controllerProps={{
          name: "inSprint",
          control,
          defaultValue: contentUpload?.inSprint || true,
        }}
        tooltipText="Should the upload show up in the Sprint section?"
      />
      <FormSubmitContainer errorText={formError}>
        <FormSecondaryButton onClick={closeModal}>Cancel</FormSecondaryButton>
        <FormPrimaryButton isLoading={isSubmitting}>
          {isUpdating ? "Update" : "Create"}
        </FormPrimaryButton>
      </FormSubmitContainer>
    </BaseForm>
  );
}

VideoContentForm.propTypes = {
  /** Function to close the parent modal. */
  closeModal: func.isRequired,

  /** Set the file to be uploaded in parts, if above threshold. */
  setMultipartFile: func.isRequired,

  /** Set the content upload file in the parent component. */
  setContentUpload: func.isRequired,

  /** The content upload object, if we're updating one. */
  contentUpload: shape(ContentUpload),

  /** Determine if the user should choose type, or if it should be predefined. */
  chooseType: bool,

  /** Determine if the user should be able to choose if it's in the sprint or not. */
  chooseInSprint: bool,

  /** Determine if the user should be able to choose which bucket to associate the content upload. */
  chooseBucket: bool,

  /** Special action to take if we want to prompt the user to choose a category. */
  promptChooseCategory: func,

  /** Action to take after the form has been saved. */
  followupAction: func,
};

VideoContentForm.defaultProps = {
  contentUpload: null,
  chooseType: true,
  chooseInSprint: false,
  chooseBucket: true,
  promptChooseCategory: () => {},
  followupAction: () => {},
};

export default VideoContentForm;