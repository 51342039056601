import axios from "axios";

function getBaseUrl() {
  return "https://api.projectcity.tv/";
}

export const baseUrl = getBaseUrl();
export const axiosInstance = axios;
export const initialState = {
  entities: [],
  isLoading: false,
};
