import { bool, shape } from "prop-types";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

import TransparentCard from "components/cards/Transparent";
import CommentsPreview from "features/comments/components/Preview";
import PostActionsModal from "components/modals/PostActions";
import Body from "components/text/Body";
import BucketUploadModal from "components/modals/BucketUpload";
import ProjectModal from "features/projects/modals/Project";
import useGetContentTypeObj from "hooks/GetContentTypeObj";
import PostVisualDisplay from "../PostVisualDisplay";
import PostHeader from "../Header";
import PostActionBar from "../ActionBar";
import { Post } from "types";

const UpperContainer = styled.div`
  padding: ${(props) => props.theme.postPadding};
  padding-top: 5px;
`;

function PostItem({ post, inModal }) {
  /**
   * Render a post object.
   *
   * The post object can be shown in various places such as the global feed or in a
   * modal.
   */

  const { contentObject, contentType } = post;
  const postContentTypeObj = useGetContentTypeObj(contentType);

  const [showPostActionsModal, hidePostActionsModal] = useModal(() => {
    return <PostActionsModal post={post} onHide={hidePostActionsModal} />;
  }, [post]);

  function getProject() {
    /** Return the project object for the given post content object. */
    const bucketModels = ["bucketupload", "textonlyfeed"];
    const projectModels = ["projectbucket"];

    if (postContentTypeObj.model === "project") return post.contentObject;
    else if (bucketModels.includes(postContentTypeObj.model))
      return contentObject.bucket.project;
    else if (projectModels.includes(postContentTypeObj.model))
      return contentObject.project;
  }

  const [showBucketUploadModal, hideBucketUploadModal] = useModal(
    () => (
      <BucketUploadModal
        bucketUpload={contentObject}
        onHide={hideBucketUploadModal}
      />
    ),
    [contentObject]
  );

  const [showProjectModal, hideProjectModal] = useModal(() => {
    return (
      <ProjectModal
        className="px-3"
        project={contentObject}
        onHide={hideProjectModal}
      />
    );
  });

  function handleModalExpand() {
    /** Depending on the content type of the post, different actions can be taken. */
    switch (postContentTypeObj.model) {
      case "bucketupload":
        return showBucketUploadModal();
      case "project":
        return showProjectModal();
      default:
        break;
    }
  }

  function getPreviewComments() {
    /** Array of comments to send to the preview section. */
    const extraComments = post.comments || [];
    return contentObject?.displayComment
      ? [contentObject.displayComment, ...extraComments]
      : extraComments;
  }

  // First check that the content object is available.
  if (contentObject === null) return null;

  return (
    <TransparentCard className={`${inModal ? "" : "mb-5"} p-0`}>
      <UpperContainer>
        <PostHeader
          user={post.createdBy}
          project={getProject()}
          action={showPostActionsModal}
        />
        <PostVisualDisplay
          contentType={postContentTypeObj}
          contentObject={contentObject}
        />
        <PostActionBar post={post} />
        {post.text && <Body size="sm">{post.text}</Body>}
      </UpperContainer>
      <CommentsPreview
        comments={getPreviewComments()}
        contentObjectId={contentObject.id}
        commentCount={contentObject.commentCount}
        contentType={postContentTypeObj}
        handleExpandComments={handleModalExpand}
        postId={post.id}
      />
    </TransparentCard>
  );
}

PostItem.propTypes = {
  /** The post object that we're rendering.  */
  post: shape(Post).isRequired,

  /** Determine if the post should be shown in a modal. */
  inModal: bool,
};

PostItem.defaultProps = {
  inModal: false,
  isLoadingComments: false,
};

export default PostItem;
