import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";

import { updateProject } from "features/projects/thunks";
import UploadPosterImageModal from "features/projects/modals/UploadPosterImage";
import StyledRadioButton from "components/controls/RadioButton";
import theme from "theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFormGroup = styled(Form.Group)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${(props) => props.theme.blue};
  margin-bottom: 0px;

  > label {
    flex-basis: 50%;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint}) {
    font-size: 14px;
  }
`;

function PublicToggle({ project, setPublicAction }) {
  /** Provide a component to toggle if a project is public or private. */

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const [currentStatus, setCurrentStatus] = useState(project.isPublic);

  const [showUploadPosterModal, hideUploadPosterModal] = useModal(() => (
    <UploadPosterImageModal onHide={hideUploadPosterModal} project={project} />
  ));

  async function handleToggle(isPublic) {
    /** Toggle the project's is public flag.  */

    // Verify that the project has a poster image uploaded.
    if (isPublic && !project.image) return showUploadPosterModal();

    if (isLoading) return;

    setIsLoading(true);

    const actionPayload = {
      projectId: project.id,
      payload: {
        isPublic,
      },
    };
    
    try {
      const result = await dispatch(updateProject(actionPayload));
      if (result.error) {
        throw new Error(result.error.message || 'Failed to update project status');
      }
      setCurrentStatus(isPublic);
      addToast('Project status updated successfully', {
        appearance: 'success',
        autoDismiss: true
      });
      // After the project has been made public, we should ask the user if they want to
      // share the project if they haven't already.
      if (!project.isPublic && isPublic) setPublicAction();
      
    } catch (error) {
      addToast(error.message || 'Failed to update project status. Please try again.', {
        appearance: 'error',
        autoDismiss: true,
      });
      // Revert the UI state back to the previous state
      setCurrentStatus(!isPublic);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Wrapper>
      <StyledFormGroup>
        <StyledRadioButton
          color={theme.pink}
          onClick={() => handleToggle(false)}
          active={!currentStatus ? "true" : null}
          type="radio"
          label="DRAFT"
          name="is-draft"
          checked={!currentStatus}
          disabled={isLoading}
        />
        <StyledRadioButton
          color={theme.yellow}
          onClick={() => handleToggle(true)}
          active={currentStatus ? "true" : null}
          type="radio"
          label="PUBLIC"
          name="is-public"
          checked={currentStatus}
          disabled={isLoading}
        />
      </StyledFormGroup>
    </Wrapper>
  );
}

PublicToggle.propTypes = {
  // The project for which we're toggling.
  project: PropTypes.object.isRequired,

  // Action to take when setting the project as public.
  setPublicAction: PropTypes.func,
};

PublicToggle.defaultProps = {
  setPublicAction: () => {},
};

export default PublicToggle;
