export const countriesListChoices = [
  {
    value: "",
    label: "Select your country",
  },
  {
    value: "US",
    label: "United States",
  },
  {
    value: "AT",
    label: "Austria",
  },
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "FR",
    label: "France",
  },
  {
    value: "BR",
    label: "Brazil",
  },
  {
    value: "IT",
    label: "Italy",
  },
  {
    value: "ES",
    label: "Spain",
  },
  {
    value: "NZ",
    label: "New Zealand",
  },
  {
    value: "JP",
    label: "Japan",
  },
  {
    value: "AU",
    label: "Australia",
  },
  {
    value: "BE",
    label: "Belgium",
  },
  {
    value: "BG",
    label: "Bulgaria",
  },
  {
    value: "CY",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    label: "CZ",
  },
  {
    value: "DK",
    label: "Denmark",
  },
  {
    value: "EE",
    label: "Estonia",
  },
  {
    value: "FI",
    label: "Finland",
  },
  {
    value: "DE",
    label: "Germany",
  },
  {
    value: "GR",
    label: "Germany",
  },
  {
    value: "HK",
    label: "Hong Kong",
  },
  {
    value: "HU",
    label: "Hungary",
  },
  {
    value: "IE",
    label: "Ireland",
  },
  {
    value: "LV",
    label: "Latvia",
  },
  {
    value: "LT",
    label: "Lithuania",
  },
  {
    value: "MT",
    label: "Malta",
  },
  {
    value: "LU",
    label: "Luxembourg",
  },
  {
    value: "NL",
    label: "Netherlands",
  },
  {
    value: "NO",
    label: "Norway",
  },
  {
    value: "PL",
    label: "Poland",
  },
  {
    value: "RO",
    label: "Romania",
  },
  {
    value: "SG",
    label: "Singapore",
  },
  {
    value: "SK",
    label: "Slovakia",
  },
  {
    value: "SI",
    label: "Slovenia",
  },
  {
    value: "CH",
    label: "Switzerland",
  },
  {
    value: "SE",
    label: "Sweden",
  },
  {
    value: "GB",
    label: "United Kingdom",
  },
];

// get google client id based on environment:
export const googleClientId =
  process.env.REACT_APP_ENV === "production"
    ? "805139660786-hp5930ceuk8vqtvsg61lfqmjqf8bsq2j.apps.googleusercontent.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "805139660786-7apdbmsvcal4iku97hu8mce85feuq6li.apps.googleusercontent.com"
    : "805139660786-25ok5nio3p5b6cd79qj3kgu954t8pqbs.apps.googleusercontent.com";
