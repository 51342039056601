import slugify from "slugify";
import moment from "moment";

import { projectTemplate, projectType } from "utils/enums";
import { isProjectCityAdmin } from "./permissions";

export function getProjectBaseUrl(project) {
  // Return the base url for a project, without any bucket.
  const projectTitleSlug = slugify(project.title, { lower: true });
  const formattedDate = moment(project.created).format("YYYY-MM-DD");
  return `/projects/${project.id}/${formattedDate}/${projectTitleSlug}`;
}

export function getProjectUrl(project) {
  // Return the full detail url for a project, including the default bucket.
  const projectTitleSlug = slugify(project.title, { lower: true });
  const formattedDate = moment(project.created).format("YYYY-MM-DD");
  let path = `/projects/${project.id}/${formattedDate}/${projectTitleSlug}`;

  if (project.defaultPageSlug) path += `/${project.defaultPageSlug}`;

  return path;
}

export function getProjectManagementBaseUrl(project) {
  // Return the base url for project management.
  return `${getProjectBaseUrl(project)}/manage`;
}

export function getProjectManagementUrl(project) {
  // Return the url for the project management page.
  return `${getProjectManagementBaseUrl(project)}/tiers`;
}

export function isProjectAdmin(user, project) {
  /* Determine if the user has admin access to a given project.

    :param user object: The user object, generally of the current user.
    :param project object: The project object that we're checking if the user is admin of.
  */
  return (
    project.teachers.map((teacher) => teacher.id).includes(user.id) ||
    project.owner === user.id ||
    isProjectCityAdmin(user)
  );
}

export function canViewSplitTotals(user, project) {
  /* Determine if the user can view split revenue amount for other users.

    :param user object: The user object, generally of the current user.
    :param project object: The project object that we're checking on.
  */
  return project.owner === user.id || isProjectCityAdmin(user);
}

export function getProjectDescriptor(project) {
  /* Returns the descriptor that should be used for the project.

     NOTE: the definition of "class" probably will change, so it's made a variable here instead of
     directly using from project.

     :param project object: The project that we're getting the descriptor for.
     :param upperCase bool: Determines if the descriptor should start with an upper case letter.
  */
  return project.template === projectTemplate.liveClass ? "Class" : "Project";
}

export function getProjectType(project) {
  /* Returns the type of the project, which helps with changing wording to be more specific to that
     type of project.

     :param project object: The project object that we're getting the type of.
     :return str: Returns the string of the type of project that we're working with.
  */
  return project.template;
}

export function isClass(project) {
  return project.type === projectType.defaultClass;
}

export function isSprintClass(project) {
  return project.type === projectType.sprintClass;
}

export function getProjectMobileType(project, isMobileEnabled) {
  if (isClass(project)) {
    return isMobileEnabled ? "mobile_class" : "default_class";
  }
  return isMobileEnabled ? "mobile_project" : "default_project";
}
