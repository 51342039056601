import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjects,
  fetchProject,
  updateProject,
  fetchProjectEnrollments,
  fetchProjectAccounts,
  createTeacher,
  searchProjects,
  deleteProject,
  createProjectSection,
  updateProjectSection,
  deleteProjectSection,
} from "./thunks";
import {
  createMediaItem,
  deleteMediaItem,
  updateMediaItem,
} from "features/mediaItems/thunks";
import {
  createEnrollment,
  refundEnrollment,
  updateEnrollment,
} from "features/enrollments/thunks";
import {
  updateBucket,
  createBucket,
  deleteBucket,
} from "features/buckets/thunks";
import {
  updateRegistrationTier,
  createRegistrationTier,
  deleteRegistrationTier,
  createDescriptions,
} from "features/tiers/thunks";
import {
  updateTierDescription,
  createTierDescription,
  deleteTierDescription,
} from "features/tierDescriptions/thunks";
import { initialState } from "features/api";
import { enrollmentStatus, projectType } from "utils/enums";

const projectInitialState = {
  ...initialState,

  // Each key in the entities will be a project "type", such as classes or recordings.
  entities: {},

  // Used to store a single project detail data.
  detail: {},

  // Used to store an active image block used to view the carousel of data.
  activeImageBlock: {},

  // The results of a project search
  search: {
    results: [],
    next: null,
  },

  // Allow users to act as other user types.
  actAs: {},
};

const projectsSlice = createSlice({
  name: "projects",
  initialState: projectInitialState,
  reducers: {
    clearProjects: (state) => {
      return projectInitialState;
    },
    activateImageBlock: (state, action) => {
      // Find the block that we've selected to get all the images
      const { imageId } = action.payload;
      const block = state.detail.imageBlocks.find((block) => {
        return block.items.some((image) => image.id === imageId);
      });
      state.activeImageBlock = { ...block, initial: imageId };
    },
    deactivateImageBlock: (state) => {
      state.activeImageBlock = {};
    },
    clearSearch: (state) => {
      state.search = projectInitialState.search;
    },
    setActAs: (state, action) => {
      state.actAs = action.payload;
    },
  },
  extraReducers: {
    [fetchProjects.fulfilled]: (state, action) => {
      function getProjectStateKey() {
        /** Get the key to store the data based on project data being fetched. */
        if (params.hasRecordings) return "recordings";
        if (params.isPurchased) return "purchased";
        else if (params.type === projectType.defaultClass) return "classes";
        else if (params.type === projectType.sprintClass)
          return "sprintClasses";
      }

      const { params, fresh } = action.meta.arg;
      const key = getProjectStateKey();

      if (key !== "all") {
        state.entities[key] = action.payload.results;
      } else {
        state.entities[key] = fresh
          ? { results: action.payload.results, next: action.payload.next }
          : {
              results: [
                ...state.entities[key].results,
                ...action.payload.results,
              ],
              next: action.payload.next,
            };
      }
    },

    [fetchProject.fulfilled]: (state, action) => {
      state.detail = {
        ...state.detail,
        ...action.payload,
      };
    },

    [updateProject.fulfilled]: (state, action) => {
      state.detail = {
        ...state.detail,
        ...action.payload,
      };
    },

    [deleteProject.fulfilled]: (state, action) => {
      if (state.detail.id === action.payload.id) {
        state.detail = {};
      }
    },

    [createRegistrationTier.fulfilled]: (state, action) => {
      state.detail.tiers.push(action.payload);
    },
    [updateRegistrationTier.fulfilled]: (state, action) => {
      // When we update a registration tier, we also need to update the project detail state.
      state.detail.tiers = state.detail.tiers.map((tier) => {
        if (tier.id === action.payload.id)
          return { ...tier, ...action.payload };
        return tier;
      });
    },
    [deleteRegistrationTier.fulfilled]: (state, action) => {
      state.detail.tiers = state.detail.tiers.filter(
        (tier) => tier.id !== action.meta.arg
      );
    },

    [createDescriptions.fulfilled]: (state, action) => {
      const { id, descriptions } = action.payload;

      state.detail.tiers = state.detail.tiers.map((tier) => {
        if (tier.id !== id) return tier;

        // This is the tier that needs to have the descriptions added.
        return { ...tier, descriptions };
      });
    },

    [createTierDescription.fulfilled]: (state, action) => {
      // When we create a new tier description, add it to the tier state data.
      state.detail.tiers = state.detail.tiers.map((tier) => {
        if (tier.id !== action.payload.registrationTier) return tier;

        // This is the tier that needs to have the description added.
        const newDescriptions = [...tier.descriptions, action.payload];
        return { ...tier, descriptions: newDescriptions };
      });
    },
    [updateTierDescription.fulfilled]: (state, action) => {
      // When we update a tier description, we need to find it and update its data.
      state.detail.tiers = state.detail.tiers.map((tier) => {
        if (tier.id !== action.payload.registrationTier) return tier;

        // This is the tier that needs to be updated.
        const newDescriptions = tier.descriptions.map((description) =>
          description.id !== action.payload.id ? description : action.payload
        );
        return { ...tier, descriptions: newDescriptions };
      });
    },
    [deleteTierDescription.pending]: (state, action) => {
      // Remove the deleted description from the project registration tier state.
      state.detail.tiers = state.detail.tiers.map((tier) => {
        const newDescriptions = tier.descriptions.filter(
          (description) => description.id !== action.meta.arg
        );
        return { ...tier, descriptions: newDescriptions };
      });
    },

    [createMediaItem.fulfilled]: (state, action) => {
      /** Add a new media block item into the project's detail data. */
      let block = state.detail.imageBlocks.find(
        (block) => block.id === action.payload.block
      );
      block.items.push(action.payload);
    },
    [updateMediaItem.fulfilled]: (state, action) => {
      /** Update the media item that was updated. */
      let block = state.detail.imageBlocks.find(
        (block) => block.id === action.payload.block
      );
      block.items = block.items.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    [deleteMediaItem.pending]: (state, action) => {
      /** Optimistically remove the project media item from its state. */
      const itemId = action.meta.arg;
      const updated = state.detail.imageBlocks.map((block) => {
        const items = block.items.filter((item) => item.id !== itemId);
        block.items = items;
        return block;
      });
      state.detail.imageBlocks = updated;
    },

    [updateBucket.fulfilled]: (state, action) => {
      /* When we update a bucket, we need to update the project detail tiers and buckets data, since
         they are related.
      */
      state.detail.buckets = state.detail.buckets.map((bucket) => {
        return bucket.id === action.payload.id ? action.payload : bucket;
      });

      state.detail.tiers = state.detail.tiers.map((tier) => {
        /* This part is a bit more complicated. Inside each tier, we need to update the bucket data
           if it's the one we updated. We need to check if we've either removed or added a the
           bucket from this tier, and modify the data accordingly.
        */
        let buckets = tier.buckets;
        const bucketId = action.payload.id;

        if (
          action.payload.registrationTiers.includes(tier.id) &&
          !tier.buckets.map((bucket) => bucket.id).includes(bucketId)
        ) {
          // The bucket needs to be added to this tier as it's being created.
          buckets = buckets.concat(action.payload);
        }
        if (
          action.payload.registrationTiers.includes(tier.id) &&
          tier.buckets.map((bucket) => bucket.id).includes(bucketId)
        ) {
          // Find and update the bucket that we just updated.
          buckets = tier.buckets.map((bucket) =>
            bucket.id === bucketId ? action.payload : bucket
          );
        } else if (
          !action.payload.registrationTiers.includes(tier.id) &&
          tier.buckets.map((bucket) => bucket.id).includes(bucketId)
        ) {
          // The bucket needs to be removed from this tier.
          buckets = buckets.filter((bucket) => bucket.id !== bucketId);
        }

        return { ...tier, buckets };
      });
    },
    [createBucket.fulfilled]: (state, action) => {
      // When we create a bucket we need to add it to the project detail buckets and tiers.
      state.detail.buckets.push(action.payload);

      // Now we can add it to the tier data, if there were tiers passed into the action.
      const { registrationTiers } = action.payload;
      if (registrationTiers && registrationTiers.length > 0) {
        state.detail.tiers = state.detail.tiers.map((tier) => {
          if (registrationTiers.includes(tier.id)) {
            tier.buckets.push(action.payload);
            return tier;
          }
          return tier;
        });
      }
    },
    [deleteBucket.pending]: (state, action) => {
      // When we delete a bucket, we need to remove it from the bucket and tiers state.
      const bucketId = action.meta.arg;
      state.detail.buckets = state.detail.buckets.filter((bucket) => {
        return bucket.id !== bucketId;
      });

      // Remove the bucket from the tier data.
      state.detail.tiers = state.detail.tiers.map((tier) => {
        const buckets = tier.buckets.filter((bucket) => bucket.id !== bucketId);
        return { ...tier, buckets };
      });
    },

    [fetchProjectEnrollments.fulfilled]: (state, action) => {
      state.detail.enrollments = action.payload;
    },

    [refundEnrollment.fulfilled]: (state, action) => {
      const enrollmentId = action.meta.arg;
      state.detail.enrollments = state.detail.enrollments.map((enrollment) => {
        if (enrollment.id === enrollmentId)
          return { ...enrollment, status: enrollmentStatus.refunded };
        return enrollment;
      });
    },
    [updateEnrollment.fulfilled]: (state, action) => {
      const enrollmentId = action.payload.id;
      state.detail.enrollments = state.detail.enrollments.map((enrollment) => {
        return enrollment.id === enrollmentId ? action.payload : enrollment;
      });
    },
    [createEnrollment.fulfilled]: (state, action) => {
      state.detail.enrollments.push(action.payload);
    },

    [createTeacher.fulfilled]: (state, action) => {
      state.detail = action.payload;
    },

    [fetchProjectAccounts.fulfilled]: (state, action) => {
      state.detail.accounts = action.payload;
    },

    [searchProjects.pending]: (state, action) => {
      state.search.isLoading = true;
    },
    [searchProjects.fulfilled]: (state, action) => {
      // Set the results and the next url for pagination of the search results.
      const results = action.payload.results.filter(
        (project) => project.image !== null
      );
      state.search = {
        ...action.payload,
        isLoading: false,
        results,
      };
    },

    [createProjectSection.fulfilled]: (state, action) => {
      // Add the new section in our project detail data.
      state.detail.sections.push(action.payload);
    },

    [updateProjectSection.fulfilled]: (state, action) => {
      // Replace the section in our project detail data.
      state.detail.sections = state.detail.sections.map((section) =>
        section.id === action.payload.id ? action.payload : section
      );
    },

    [deleteProjectSection.pending]: (state, action) => {
      // Remove the section from our project detail data.
      state.detail.sections = state.detail.sections.filter(
        (section) => section.id !== action.meta.arg.sectionId
      );
    },
  },
});

export const {
  clearProjects,
  clearSearch,
  activateImageBlock,
  deactivateImageBlock,
  setActAs,
} = projectsSlice.actions;

export default projectsSlice.reducer;
