import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { shape } from "prop-types";
import { useToasts } from "react-toast-notifications";

import { updateProject } from "features/projects/thunks";
import { Project } from "types";
import theme from "theme";

const ToggleButton = styled.div`
  display: inline-block;
  background: ${props => props.isEnabled ? theme.blue : theme.yellow};
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  transform: rotate(var(--rotation, 0deg));
  
  &:hover {
    background: ${props => props.isEnabled ? theme.yellow : theme.blue};
    transform: translateY(-2px) rotate(var(--rotation, 0deg));
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    
    span {
      color: ${props => props.isEnabled ? theme.blue : theme.yellow};
    }
  }
  
  &:active {
    transform: translateY(3px) rotate(var(--rotation, 0deg));
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.1s ease-in-out;
  }
  
  span {
    color: ${props => props.isEnabled ? theme.yellow : theme.blue};
    font-family: "Basis Grotesque Mono";
    font-size: 21px;
    text-transform: uppercase;
    transition: color 0.2s ease-in-out;
  }
`;

const EnabledLabel = styled.span`
  color: ${theme.yellow};
  font-family: "Basis Grotesque Mono";
  font-size: 21px;
  text-transform: uppercase;
`;

function MobileToggle({ project }) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(Boolean(project?.showInMobile));
  const buttonRef = React.useRef(null);

  const setRandomRotation = () => {
    if (buttonRef.current) {
      const randomDegree = (Math.random() * 9) - 4; // Random value between -3 and 3 degrees
      buttonRef.current.style.setProperty('--rotation', `${randomDegree}deg`);
    }
  };

  async function handleToggle() {
    if (isLoading) return;
    setIsLoading(true);

    const actionPayload = {
      projectId: project.id,
      payload: {
        show_in_mobile: !isEnabled,
      },
    };

    try {
      await dispatch(updateProject(actionPayload));
      setIsEnabled(!isEnabled);
      addToast(`Successfully ${!isEnabled ? 'enabled' : 'disabled'} mobile access`, {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (error) {
      console.error("API request failed:", error);
      addToast('Failed to update mobile access', {
        appearance: 'error',
        autoDismiss: true
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ToggleButton 
        onClick={handleToggle} 
        isEnabled={isEnabled} 
        ref={buttonRef}
        onMouseEnter={setRandomRotation}
        onMouseLeave={() => buttonRef.current?.style.setProperty('--rotation', '0deg')}
      >
        <span>{isEnabled ? 'DISABLE' : 'ENABLE'}</span>
      </ToggleButton>
    </>
  );
}

MobileToggle.propTypes = {
  project: shape(Project).isRequired,
};

export default MobileToggle;
