import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import Header from "components/text/Header";
import CopyHeader from "components/text/CopyHeader";
import DashboardContainer from "components/containers/Dashboard";
import DashboardLoadingContainer from "components/loading/DashboardContainer";
import LoadingContainer from "components/loading/Container";
import ProjectTile from "features/projects/components/Tile";
import { projectType } from "utils/enums";
import projectCityApi, { useGetProjectListQuery } from "services/projectCity";

const HeaderContainer = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    width: 70%;
    margin-left: 15%;
  }
`;

const FundingProjectsContainer = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin: 0 10%;
  }
`;

const HeadlineContainer = styled.div`
  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: ${(props) => props.theme.vSpacingXl};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }
`;

function AllProjectsRoute() {
  /** Show all the projects and allow the user to browse into them. */

  const { data: fundingProjectsData, isLoading: isFundingLoading } = useGetProjectListQuery({
    type: projectType.fundingProject
  });

  const { data: otherProjectsData, isLoading: isOtherLoading } = useGetProjectListQuery({
    type: projectType.defaultProject
  });

  const [loadNextPage] = projectCityApi.endpoints.getNextProjectPage.useLazyQuery();

  const fundingProjects = fundingProjectsData?.results || [];
  const otherProjects = otherProjectsData?.results || [];
  const nextUrl = otherProjectsData?.next;

  if (isOtherLoading || isFundingLoading) return <DashboardLoadingContainer text="Loading projects" />;

  function renderProjects({ projects, fip }) {
    return projects.map((project, index) => (
      <Col className="py-2" sm={12} md={4} lg={3} key={index}>
        <ProjectTile
          project={project}
          isEven={index % 2 === 0}
          push={isMobile && index % 2 === 0 ? "left" : "right"}
          fip={fip}
          withRainbowBorder={fip}
        />
      </Col>
    ));
  }

  return (
    <DashboardContainer fluid className="px-2">
      <HeaderContainer>
        <Header
          top="featured"
          bottom="projects"
          className="mb-3 pt-1"
          color="rainbow"
        />
        <HeadlineContainer>
          {/* <CopyHeader>
            Coming soon, Project City is launching a new way for artists to fund
            their dream projects with F-IP.
          </CopyHeader> */}
        </HeadlineContainer>
      </HeaderContainer>
      <FundingProjectsContainer>
        <Row className="mt-3">
          {renderProjects({ projects: fundingProjects, fip: true })}
        </Row>
      </FundingProjectsContainer>

      <div className="my-5">
        <HeaderContainer>
          <Header top="other" bottom="projects" className="mb-3" />
          <HeadlineContainer>
            <CopyHeader>
              Check out some of the projects that students and creators are
              working on.
            </CopyHeader>
          </HeadlineContainer>
        </HeaderContainer>
        <div>
          <InfiniteScroll
            dataLength={otherProjects ? otherProjects.length : 0}
            title="projects"
            next={() => loadNextPage({ next: nextUrl, type: projectType.defaultProject })}
            hasMore={nextUrl !== null}
            loader={<LoadingContainer />}
            style={{ overflow: "hidden" }}
          >
            <Row>{renderProjects({ projects: otherProjects })}</Row>
          </InfiniteScroll>
        </div>
      </div>
    </DashboardContainer>
  );
}

export default AllProjectsRoute;
