import { string } from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledText = styled.h5`
  font-family: "Basis Grotesque Pro Bold";
  font-size: 28px;
  line-height: 36px;
  color: ${(props) => props.theme[props.color]};
  margin-bottom: 0;

  // Sometimes there can be a p tag from rich text editor.
  > p {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    font-size: 20px;
    line-height: 24px;
  }

  ${(props) =>
    props.size === "sm" &&
    `
    font-size: 22px;
    line-height: 24px;

    @media (max-width: ${(props) => props.theme.smBreakpoint}) {
      font-size: 17px;
      line-height: 18px;
    }
  `}
`;

function CopyHeader(props) {
  return <StyledText {...props}>{props.children}</StyledText>;
}

CopyHeader.propTypes = {
  color: string,
};

CopyHeader.defaultProps = {
  color: "lime",
};

export default CopyHeader;
