import React from "react";
import { shape, object } from "prop-types";

import ProjectActAsSelect from "components/controls/ProjectActAsSelect";
import SettingsContainer from "components/containers/Settings";
import ProjectPublicToggle from "features/projects/components/PublicToggle";
import MobileToggle from "features/projects/components/MobileToggle";
import ProjectSettingSection from "features/projects/components/SettingControl";
import useFeedShare from "hooks/FeedShare";
import { isClass } from "utils/projects";
import { Project } from "types";

function ProjectSettingsContainer({ project, actAsContext, ...props }) {
  /** Section for a project owner to see special project settings. */

  const descriptor = isClass(project) ? "class" : "project";
  const capitalDescriptor =
    descriptor.charAt(0).toUpperCase() + descriptor.slice(1);

  const showConfirmShareModal = useFeedShare(
    "project",
    `Your ${descriptor} is public!`,
    project.id
  );

  return (
    <SettingsContainer {...props}>
      <ProjectSettingSection
        label="Currently viewing as:"
        className="mb-2"
        tooltip={`Use this drop down to see what your ${descriptor} looks like from the point of
                  view of someone who has purchased a specific level of access through one of
                  the pay buttons or as a public member`}
      >
        <ProjectActAsSelect project={project} {...actAsContext} />
      </ProjectSettingSection>
      <ProjectSettingSection
        label={`${capitalDescriptor} Status:`}
        tooltip={`Draft ${descriptor}s are private and only viewable by collaborators / teachers until you make it public`}
      >
        <ProjectPublicToggle
          project={project}
          setPublicAction={
            !project.hasBeenShared ? showConfirmShareModal : () => {}
          }
        />
      </ProjectSettingSection>
      <ProjectSettingSection
        size="sm"
        label={`Mobile: ${project.showInMobile ? 'ENABLED' : 'DISABLED'}`}
        tooltip={`Enable this ${descriptor} to be accessible in the mobile app`}
        className="mt-2"
      >
        <MobileToggle project={project} />
      </ProjectSettingSection>
    </SettingsContainer>
  );
}

ProjectSettingsContainer.propTypes = {
  /** The project for which we're viewing the info bucket. */
  project: shape(Project).isRequired,

  /** The context for acting as a given user type. */
  actAsContext: object.isRequired,
};

export default ProjectSettingsContainer;
